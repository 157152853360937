<template>
    <div class="fondo-degradado-recover d-flex justify-content-center align-items-center">Proximamente</div>
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import {mapState} from "vuex"

    export default {
        name: "App",
        components: {
            Footer
        },
        data(){
            return{
                
            }
        },
        methods:{

            

        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .fondo-degradado-recover{
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:80px ;
        font-weight: bold;
        margin-bottom:-200px;
    }
</style>